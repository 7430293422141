<template>
    <div class="zoe">
        <div class="state" v-if="state == 'init' && !op">
            <p style="font-size:36px; color:var(--success); margin-top:50px;">היי {{user.name}}</p>
            <p style="font-size:24px;">מה תרצה לעשות?</p>

            <el-button type="primary" class="init-btn" @click="state = 'clients'">לקוחות</el-button>
            <el-button type="primary" class="init-btn">סוכנים</el-button>
            <el-button type="primary" class="init-btn">מוצרים</el-button>
        </div>
        <div class="state" v-if="state == 'clients' && !op">
            <el-button type="warning" class="init-btn" @click="op = 'examine-client'">נתח לקוח ספציפי</el-button>
        </div>

        <div class="state" v-if="state == 'clients' && op == 'examine-client'">
            <ExamineSingleClient />
        </div>

    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { computed, watch } from '@vue/runtime-core'
import store from '../../store'
import Swal from 'sweetalert2'
import ExamineSingleClient from '../../components/Zoe/ExamineSingleClient.vue';

export default {
components:{ExamineSingleClient},
setup(){
    const state = ref("init");
    const op = ref(null);

    const user = ref(computed(() => {
        return store.getters.user
    }))

    return{
        user, state, op
    }
}
}
</script>

<style scoped>
.zoe{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.state{
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    padding: 5px;
    overflow: hidden;
    overflow-y: auto;
}
.init-btn{
    width: 350px;
    margin: 0;
    margin-top: 10px;
    font-size: 20px;
}
</style>